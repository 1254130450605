<template>
  <div class="content">
    <div class="app">
      <div class="app-left">
        <div class="app-text">
          <h2>智慧城市APP</h2>
          <div>整合城市各行业移动应用服务</div>
          <p>VR、AR等新型产业核心平台</p>
          <p>
            数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务
          </p>
        </div>
        <div class="app-rounds"></div>
      </div>
      <!-- <div class="app-right"></div> -->
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.app {
  width: 100%;
  height: calc(100vh - 287px);
  display: flex;
  padding: 0 18%;
  box-sizing: border-box;
  justify-content: space-between;
  background: url("../../../../assets/image/cityImage/组 296.png") no-repeat;
  .app-left {
    color: #fff;
    width: 100%;
    height: 100%;
    .app-text {
      h2 {
        font-size: 28px;
        font-weight: 600;
        margin: 51px 0 0 0;
      }
      div {
        font-size: 20px;
        font-weight: 600;
        margin: 25px 0 20px 0;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }
    }
    .app-rounds {
      width: 80%;
      height: 50%;
      // position: relative;
      margin-top: 20px;
      margin-left: 10%;
      text-align: center;
      background: url("../../../../assets/image/cityImage/组 329.png") no-repeat;
      background-size: contain;
    }
  }
  .app-right {
    width: 25%;
    height: 80%;
    transform: translateY(10%);
    background: url("../../../../assets/image/cityImage/app.png") no-repeat;
    background-size: contain;
  }
}
</style>